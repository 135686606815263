import React from 'react';
import Layout from '../components/layout/layout';
import Contact from '../components/contactus/contactus';
import JoinPropme  from "../components/joinpropme/joinpropme";

class ContactUs extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return(
      <Layout>
        <Contact />
        <JoinPropme />
      </Layout>
    )
  }
}

export default ContactUs