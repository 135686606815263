import React from 'react';
import './styles.css'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class JoinPropme extends React.Component{
  constructor(props){
    super(props);
    this.state={
      name: '',
      email:'',
      feedbackMsg:''
    }
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": "getintouch",
        ...this.state,
      }),
    })
    addToMailchimp(this.state.email,{
      "FNAME": this.state.name,
      "SUBJECT":"Get in Touch"
    })
    .then((response) => {
      console.log(response);
      this.setState({
        feedbackMsg: "Form submitted successfully!",
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        feedbackMsg: "Email already exists",
      })
    })
    console.log('Form submitted');
  }

  render(){
    return(
      <div className="joinpropme">
        <div className="joinpropmeBox">
          <h1 className="propmeheading">Join the Propme Community</h1>
          <p className="propmesubdesc">If you would like to stay updated on any news and upcoming events enter your information below.</p>
          <div className="propmeinputsDiv" onSubmit={this.handleSubmit}>
            <form name="getintouch" method="POST">
              <input className="name" type="text" placeholder="Name*" name="name" id="NAME" onChange={this.handleChange} required/>
              <input className="email" type="text" placeholder="Email Address*" name="email" id="email" onChange={this.handleChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" size="30" required/>
              <button className="signupbtn">SIGN UP</button>
            </form>
          </div>
          <p className="propmesubdesc">{this.state.feedbackMsg}</p>
        </div>
      </div>
    )
  }
}

export default JoinPropme